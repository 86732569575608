import React from 'react';
import styled from 'styled-components';
import Typography from '../atoms/typography';
import Button from '../atoms/button';
import Spacer from '../atoms/spacer';
import Card from '../atoms/card';
import { Col, Row } from 'react-grid-system';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
import panboxBusiness from '../../images/panbox-business.png';

const CardContent = styled.div`
  padding: 1rem;
`;

const ImageWrapper = styled.div`
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  margin: auto;
`;

export interface ICompanyApplicationCard {
  title: string;
  description: string;
  onClick: () => void;
  ctaTitle: string;
}

const CompanyApplicationCard: React.FC<ICompanyApplicationCard> = ({
  title,
  description,
  onClick,
  ctaTitle,
  ...props
}) => {
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <Card {...props}>
      <ImageWrapper>
        <Image src={panboxBusiness} alt="dator samtal" />
      </ImageWrapper>
      <CardContent>
        <Spacer />
        <Typography color="primary" variant={IS_MOBILE ? 'h3' : 'h2'}>
          {title}
        </Typography>
        <Spacer />
        <Typography color="textPrimary">{description}</Typography>
        <Spacer />
        {onClick && ctaTitle && (
          <Row>
            <Col xs={6} md={8}>
              <Button primary onClick={onClick}>
                {ctaTitle}
              </Button>
            </Col>
          </Row>
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyApplicationCard;
