import React from 'react';
import ContactForm from '../components/organisms/contactForm';
import { Col, Row } from 'react-grid-system';
import HeroBanner from '../components/organisms/heroBanner';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import CompanyApplicationCard from '../components/molecules/companyApplicationCard';
import { navigate } from 'gatsby';
import theme from '../styles/theme';
import styled from 'styled-components';
import Container from '../components/atoms/container';
import SEO from '../components/seo';

const ContactPageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.pageBackground};
`;

const TypographySubHeader = styled(Typography)`
  font-weight: normal;
`;

const ContactPage = () => {
  const handleClick = () => {
    navigate('/foretagsansokan');
  };

  return (
    <>
      <SEO title="Kontakta oss" />
      <HeroBanner
        leftPane={
          <>
            <Spacer spacing={6} />
            <Typography variant="header" color="textSecondary">
              Kontakta oss
            </Typography>
            <Spacer />
          </>
        }
      />
      <ContactPageWrapper theme={theme}>
        <Spacer spacing={4} />
        <Container>
          <Row align="end">
            <Col md={8}>
              <ContactForm
                showLabels={true}
                header={
                  <>
                    <Typography color="primary" variant="h2">
                      Skicka ett meddelande!
                    </Typography>
                    <Spacer />
                    <TypographySubHeader color="textPrimary" variant="h3">
                      Skicka ett meddelande till oss så återkommer vi inom kort!
                    </TypographySubHeader>
                    <Spacer spacing={2} />
                  </>
                }
                ctaTitle="Skicka"
                backgroundColor="formBackground"
              />
            </Col>
            <Col md={4}>
              <CompanyApplicationCard
                title="panBox för företag"
                description="Är du intresserad av vad panBox skulle innebära för ditt företag? Fyll i ansökan så kontaktar vi dig!"
                ctaTitle="Företagsansökan"
                onClick={handleClick}
              />
            </Col>
          </Row>
        </Container>
        <Spacer spacing={4} />
      </ContactPageWrapper>
    </>
  );
};

export default ContactPage;
