import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { ContactFormSchema } from '../../services/validationSchema';
import { Form, Formik, Field, FieldProps } from 'formik';
import TextInput from '../molecules/textInput';
import Typography from '../atoms/typography';
import Button from '../atoms/button';
import Spacer from '../atoms/spacer';
import { ColorType } from '../atoms/interfaces';
import styled, { css } from 'styled-components';
import { submitNetlifyForm } from '../../api';
import { contactFormFields, Forms } from '../../services/formData';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
import useSnackbar from '../../hooks/useSnackbar';
import Checkbox from '../atoms/checkbox';
import HoneyPotHidden from '../HoneyPotHidden';
import Routes from '../../services/routes';

interface IFormWrapper {
  backgroundColor?: ColorType;
  isMobile: boolean;
}

export interface IContactForm {
  header: React.ReactNode;
  showLabels?: boolean;
  ctaTitle: string;
  backgroundColor?: ColorType;
  termsColor?: ColorType;
  isPrimaryButton?: boolean;
}

export interface IContactFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  message: string;
  tos: boolean;
}

const FormWrapper = styled.div<IFormWrapper>`
  padding-right: 24px;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.textPrimary};
  ${(props: { isMobile: boolean }) =>
    props.isMobile
      ? css`
          padding-top: 24px;
          padding-bottom: 24px;
        `
      : css`
          padding-top: 60px;
        `}
`;

const StyledCol = styled.div`
  width: 100%;
  text-align: end;
`;

const UnderlineTypography = styled(Typography)`
  text-decoration: underline;
  font-weight: bold;
`;

const StyledSpan = styled.span`
  color: red;
`;

const TermsTypography = styled(Typography)`
  margin-left: 12px;
`;

const StyledLabel = styled.label`
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
`;

const initialFormValues: IContactFormValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  message: '',
  tos: false
};

const ContactForm: React.FC<IContactForm> = (props) => {
  const [loading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const { handleMessage } = useSnackbar();
  const { IS_MOBILE } = useScreenClassHelper();

  const handleCheckboxChange = (formik: any) => {
    formik.setFieldValue('tos', !checked);
    setChecked(!checked);
  };

  const handleSubmit = async (values: IContactFormValues, formik: any) => {
    try {
      setIsLoading(true);
      const success = await submitNetlifyForm(Forms.CONTACT, values);

      if (success) {
        handleMessage('Tack för ditt meddelande vi återkommer så fort vi kan.');
        formik.resetForm();
      } else {
        handleMessage(
          'Formuläret kunde inte skickas. Vänligen försök igen eller kontakta support'
        );
      }

      setIsLoading(false);
    } catch (e) {
      handleMessage(
        'Formuläret kunde inte skickas. Vänligen försök igen eller kontakta support'
      );
    }
  };

  const getSize = (name: string) => {
    switch (name) {
      case 'tos':
        return 8;
      default:
        return 12;
    }
  };

  const generatePlaceHolderValue = (item: any) =>
    isRequired(item) ? `${item.placeholder}*` : item.placeholder;

  const isRequired = (item: any) => item.placeholder !== 'Telefonnummer';

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={ContactFormSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form
          data-netlify="true"
          action="/"
          name={Forms.CONTACT}
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value={Forms.CONTACT} />
          <HoneyPotHidden />
          <FormWrapper
            isMobile={IS_MOBILE}
            backgroundColor={props.backgroundColor}
          >
            {props.header}
            <Row justify="between">
              {contactFormFields.map((item) => (
                <Col key={item.name} md={getSize(item.name)}>
                  <Field name={item.name}>
                    {({ field, meta }: FieldProps) => (
                      <>
                        <Typography>
                          {props.showLabels && item.placeholder}
                          <StyledSpan>
                            {props.showLabels && isRequired(item) ? '*' : null}
                          </StyledSpan>
                        </Typography>
                        <TextInput
                          {...field}
                          {...item}
                          color={props.termsColor}
                          placeholder={generatePlaceHolderValue(item)}
                          errorMsg={
                            meta.touched && meta.error ? meta.error : undefined
                          }
                        />
                      </>
                    )}
                  </Field>
                  <Spacer />
                </Col>
              ))}
              <Col xs={8}>
                <StyledLabel>
                  <Checkbox
                    checked={checked}
                    onChange={() => handleCheckboxChange(formik)}
                    name="tos"
                  />
                  <TermsTypography variant="body" color={props.termsColor}>
                    Jag har läst och förstår{' '}
                    <UnderlineTypography
                      color={props.termsColor}
                      variant="link"
                      to={Routes.TERMS_AND_CONDITIONS}
                    >
                      villkoren{' '}
                    </UnderlineTypography>
                  </TermsTypography>
                </StyledLabel>
              </Col>
              <Spacer />
              <Col xs={12} md={4}>
                <StyledCol>
                  <Button
                    primary={props.isPrimaryButton}
                    isMobile={IS_MOBILE}
                    isLoading={loading}
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    {props.ctaTitle}
                  </Button>
                </StyledCol>
              </Col>
            </Row>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
